import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Map
import androidx.compose.material.icons.filled.Train
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.unit.dp
import kotlinx.browser.window

@Composable
fun IconRow() {
    Row(
        horizontalArrangement = Arrangement.spacedBy(2.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        IconButton(
            onClick = {
                window.open(
                    url = "dynmap",
                    target = "_blank"
                )?.focus()
            }
        ) {
            Icon(
                imageVector = Icons.Filled.Map,
                contentDescription = "Dynmap"
            )
        }

        IconButton(
            onClick = {
                window.open(
                    url = "sysmap",
                    target = "_blank"
                )?.focus()
            }
        ) {
            Icon(
                imageVector = Icons.Filled.Train,
                contentDescription = "Sysmap"
            )
        }
    }
}